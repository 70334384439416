var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  gitInfo: function gitInfo(state) {
    return state.app.gitInfo;
  },
  token: function token(state) {
    return state.user.token;
  },
  activeMenu: function activeMenu(state) {
    return state.app.activeMenu;
  },
  breadcrumb: function breadcrumb(state) {
    return state.app.breadcrumb;
  },
  // ---
  user: function user(state) {
    return state.user.user;
  },
  permission: function permission(state) {
    return state.permission.permission;
  },
  acl: function acl(state) {
    return state.permission.acl;
  },
  rules: function rules(state) {
    return state.permission.rules;
  },
  // ---
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  notifications: function notifications(state) {
    return state.notifications;
  }
};
export default getters;