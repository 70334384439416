import "core-js/modules/es.object.to-string";
var defaultActiveMenu = '/dashboard';
var routes = [{
  path: '/porte',
  name: 'Porte',
  order: 21,
  meta: {
    title: 'Gestion des portes',
    icon: 'site'
  },
  redirect: {
    name: 'PorteModule'
  },
  hidden: true,
  component: function component() {
    return import('@/layout');
  },
  children: [// PORTES
  // ----------------
  // Liste des Portes
  {
    path: 'list',
    hidden: true,
    component: function component() {
      return import('@/views/app/porte/PorteModule');
    },
    name: 'PorteModule',
    meta: {
      title: 'Liste des Portes',
      activeMenu: defaultActiveMenu
    }
  }, // Fiche d'une Porte
  {
    path: ':porteId(\\d+)',
    hidden: true,
    component: function component() {
      return import('@/views/app/porte/PorteIndex');
    },
    name: 'PorteIndex',
    redirect: {
      name: 'PorteView'
    },
    meta: {
      title: 'Fiche d\'une porte',
      activeMenu: defaultActiveMenu
    },
    children: [// Contenu par défaut
    {
      path: '',
      component: function component() {
        return import('@/views/app/porte/PorteView');
      },
      name: 'PorteView',
      meta: {
        title: 'Vue détaillée d\'une porte',
        activeMenu: defaultActiveMenu
      }
    }, // Liste des Interventions d'une porte
    {
      path: 'interventions',
      hidden: true,
      component: function component() {
        return import('@/views/app/intervention/InterventionModule');
      },
      name: 'InterventionPorteModule',
      meta: {
        title: 'Interventions d\'une porte',
        activeMenu: defaultActiveMenu
      }
    }, // Liste des Documents d'une porte
    {
      path: 'documents',
      hidden: true,
      component: function component() {
        return import('@/views/app/document/DocumentModule');
      },
      name: 'DocumentPorteModule',
      meta: {
        title: 'Documents d\'une porte',
        activeMenu: defaultActiveMenu
      }
    }, // Liste des Tickets d'une porte
    {
      path: 'tickets',
      hidden: true,
      component: function component() {
        return import('@/views/app/ticket/TicketModule');
      },
      name: 'TicketPorteModule',
      meta: {
        title: 'Tickets d\'une porte',
        activeMenu: defaultActiveMenu
      }
    }, // Création d'un Ticket
    {
      path: 'ticket-new',
      hidden: true,
      component: function component() {
        return import('@/views/app/ticket/TicketNew');
      },
      name: 'TicketPorteNew',
      meta: {
        title: 'Création d\'un ticket',
        activeMenu: defaultActiveMenu
      }
    }]
  }]
}];
export default routes;