import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store';
import router from '@/router';
import { getToken } from '@/helpers/auth';
import i18n from '@/i18n';
/* ---------------------------------------
 *  Create an axios instance
 * ---------------------------------------
 *  cf. https://github.com/axios/axios
 *
 */

var service = axios.create({
  // `baseURL` will be prepended to `url` unless `url` is absolute.
  // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
  // to methods of that instance.
  baseURL: process.env.VUE_APP_BASE_API,
  // `withCredentials` indicates whether or not cross-site Access-Control requests
  // should be made using credentials
  // withCredentials: true,
  // `timeout` specifies the number of milliseconds before the request times out.
  // If the request takes longer than `timeout`, the request will be aborted.
  timeout: 5000 // 5s

});
/* ---------------------------------------
 *  Request interceptor
 * ---------------------------------------
 *
 *  - Ajout du header 'Authorization' avec le token
 *
 */

service.interceptors.request.use(function (config) {
  if (store.getters.token) {
    config.headers.Authorization = getToken();
  }

  return config;
}, function (error) {
  // console.log(error)
  return Promise.reject(error);
});
/* ---------------------------------------
 *  Response interceptor
 * ---------------------------------------
 *
 *  - si le 'HTTP status code' vaut 200,
 *    on retour la propriété `data` de la réponse
 *  - sinon on affiche le code de réponse reçu
 *    en tant que message
 *
 */

service.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // if (response.status !== 200) {
  if (response.status.toString().substring(0, 1) !== '2') {
    Message({
      message: response.status || 'Error',
      type: 'error',
      duration: 5 * 1000
    }); // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

    /*
    if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      // to re-login
      MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        confirmButtonText: 'Re-Login',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      })
    }
    */

    return Promise.reject(new Error(response.status || 'Error'));
  }

  return response.data;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  var message = error.response && error.response.data.message || error.response && error.response.data.sqlMessage || error.message;
  var str = i18n.t(message);
  Message({
    message: str,
    type: 'error',
    duration: 3 * 1000
  });

  if (error.response) {
    if (error.response.status === 403 || error.response.status === 401) {
      store.dispatch('user/resetToken').then(function () {
        router.push('/login');
      });
    }
  }
  /* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */


  return Promise.reject();
});
export default service;