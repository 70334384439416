import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.reduce";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.object.values";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.iterator";
import _toConsumableArray from "/var/www/lacroix_test/extranet/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */
import Vue from 'vue';
import Router from 'vue-router';

function routesLoader(root, files) {
  var routes = files.keys().reduce(function (routes, path) {
    var name = path.replace(/^\.\/(.*)\.\w+$/, '$1');
    var value = files(path);
    routes[root + '_' + name] = value.default;
    return routes;
  }, {});
  return [].concat.apply([], Object.values(routes));
}

var crmRoutes = routesLoader('crm', require.context('./routes/crm', true, /\.js$/));
var adminRoutes = routesLoader('admin', require.context('./routes/admin', true, /\.js$/));
var appRoutes = routesLoader('app', require.context('./routes/app', true, /\.js$/)); // tri des menus en fonction de la clé "order"

crmRoutes.sort(function (a, b) {
  return a.order - b.order;
});
adminRoutes.sort(function (a, b) {
  return a.order - b.order;
});
appRoutes.sort(function (a, b) {
  return a.order - b.order;
});
export var Routes = [{
  path: '/login',
  name: 'Login',
  component: function component() {
    return import('@/views/login');
  },
  hidden: true
}, {
  path: '/password-reminder',
  name: 'PasswordReminder',
  component: function component() {
    return import('@/views/password_reminder');
  },
  hidden: true
}, {
  path: '/password-reset/:pwdResetToken',
  name: 'PasswordReset',
  component: function component() {
    return import('@/views/password_reset');
  },
  hidden: true
}, {
  path: '/404',
  name: '404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}, {
  path: '/401',
  name: '401',
  component: function component() {
    return import('@/views/401');
  },
  hidden: true
}, {
  path: '/',
  name: '/',
  component: function component() {
    return import('@/layout');
  },
  redirect: {
    name: 'Home'
  },
  hidden: true,
  children: [{
    path: '/',
    name: 'Home',
    redirect: {
      name: 'UserDashboard'
    },
    // component: () => import('@/views/home/HomeModule'),
    meta: {
      title: 'Accueil',
      activeMenu: 'Home',
      breadcrumb: false
    }
  }]
}, {
  path: '/admin',
  name: 'Admin',
  component: function component() {
    return import('@/layout');
  },
  redirect: 'noRedirect',
  meta: {
    title: 'Administration',
    icon: 'admin'
  },
  children: _toConsumableArray(adminRoutes)
}, {
  path: '/crm',
  name: 'CRM',
  component: function component() {
    return import('@/layout');
  },
  redirect: 'noRedirect',
  meta: {
    title: 'CRM',
    icon: 'crm'
  },
  children: _toConsumableArray(crmRoutes)
}].concat(_toConsumableArray(appRoutes), [// 404 page must be placed at the end !!!
{
  path: '*',
  name: '*',
  redirect: '/404',
  hidden: true
}]); // ===========================

Vue.use(Router);

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: Routes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
} // const router = new Router({ appRoutes })

export default router;