import "core-js/modules/es.object.to-string";
import "/var/www/lacroix_test/extranet/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.array.iterator.js";
import "/var/www/lacroix_test/extranet/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.promise.js";
import "/var/www/lacroix_test/extranet/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.object.assign.js";
import "/var/www/lacroix_test/extranet/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import 'element-ui/lib/theme-chalk/display.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/reset.css';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/fr'; // lang i18n
// import DataTables and DataTablesServer together

import VueDataTables from 'vue-data-tables';
import DynamicForm from 'vue-dynamic-form-component';
import ImageUploader from 'vue-image-upload-resize';
import ElTableDraggable from 'element-ui-el-table-draggable';
import VueScrollTo from 'vue-scrollto';
import '@/styles/index.scss'; // global css

import i18n from './i18n';
import App from './App';
import store from './store';
import router from './router';
import '@/icons'; // icon

import '@/permission'; // permission control

import '@/helpers/directives'; // all directives

import '@/helpers/filters'; // all filters
// Resize plugin

import VueWindowSize from 'vue-window-size';
Vue.use(VueWindowSize); // set ElementUI lang to FR

Vue.use(ElementUI, {
  locale: locale
});
Vue.use(VueDataTables);
Vue.use(DynamicForm);
Vue.use(ImageUploader);
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: -60,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});
Vue.component('ElTableDraggable', ElTableDraggable);
Vue.config.disableNoTranslationWarning = true;
Vue.config.productionTip = false;
Vue.prototype.$env = {
  isDev: process.env.NODE_ENV === 'development',
  isStage: process.env.NODE_ENV === 'staging',
  isProd: process.env.NODE_ENV === 'production',
  showAppDebug: process.env.NODE_ENV === 'development' && process.env.VUE_APP_SHOW_DEBUG && process.env.VUE_APP_SHOW_DEBUG === 'true',
  presetAppLogin: process.env.NODE_ENV === 'development' && process.env.VUE_APP_PRESET_LOGIN && process.env.VUE_APP_PRESET_LOGIN === 'true'
};
Vue.prototype.$apiEndpoint = process.env.VUE_APP_BASE_API;
Vue.prototype.$remotePublicUrl = process.env.VUE_APP_REMOTE_PUBLIC;
Vue.prototype.$remoteFacturePublicUrl = process.env.VUE_APP_REMOTE_PUBLIC + '/uploads/factures/';

Vue.prototype.$loader = function (value) {
  var _this = this;

  return new Promise(function (resolve) {
    var options = {
      fullscreen: true,
      lock: true,
      // background: 'transparent',
      background: 'rgba(0, 0, 0, 0.8)',
      text: 'Chargement en cours...',
      target: 'section#appMain'
    };

    if (value) {
      ElementUI.Loading.service(options);
    } else {
      _this.$loading().close();
    }

    resolve();
  });
}; // eslint-disable-next-line


new Vue({
  el: '#app',
  i18n: i18n,
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});