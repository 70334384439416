import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.string.split";
import Vue from 'vue';
import store from '@/store';
import Inputmask from 'inputmask';
Vue.directive('mask', {
  // https://github.com/RobinHerbots/Inputmask#usage
  // https://robinherbots.github.io/Inputmask/
  bind: function bind(el, binding) {
    Inputmask(binding.value).mask(el.getElementsByTagName('INPUT')[0]);
  }
});
Vue.directive('permission', {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var role = store.getters && store.getters.user && store.getters.user.role;

    if (value && value instanceof Array && value.length > 0) {
      var permissionRoles = value;
      var hasPermission = permissionRoles.includes(role);

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error('need roles! Like v-permission="[\'admin\',\'editor\']"');
    }
  }
}); // exemple d'appel: v-can:myrule="'edit'"
// exemple d'appel: v-can:myrule="['edit','validate']"

Vue.directive('can', {
  inserted: function inserted(el, binding, vnode) {
    var permission = store.getters && store.getters.rules && store.getters.rules.length > 0 && JSON.parse(store.getters.rules);
    var rules = permission && permission[binding.arg] && permission[binding.arg].split(',');
    var hasPermission = rules && rules.includes(binding.value);

    if (!hasPermission && vnode.elm) {
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  }
});
Vue.directive('cant', {
  inserted: function inserted(el, binding, vnode) {
    var permission = store.getters && store.getters.rules && store.getters.rules.length > 0 && JSON.parse(store.getters.rules);
    var rules = permission && permission[binding.arg] && permission[binding.arg].split(',');
    var hasPermission = rules && rules.includes(binding.value);

    if (hasPermission && vnode.elm) {
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  }
});
Vue.directive('restrict', {
  bind: function bind(el, binding) {
    el.addEventListener('keydown', function (e) {
      // delete, backpsace, tab, escape, enter, negative sign (-)
      var special = [46, 8, 9, 27, 13, 109];

      if (binding.modifiers.decimal) {
        // decimal(numpad), period
        special.push(110, 190);
      } // un seul signe de la virgule representée par le . du pavé numérique


      if ((e.keyCode === 110 || e.keyCode === 190) && e.target.value.indexOf('.') !== -1) {
        e.preventDefault(); // prevent
      } // 1 seul signe moins pour les nombres négatifs


      if (e.keyCode === 109 && e.target.value.indexOf('-') !== -1) {
        e.preventDefault(); // prevent
      } // special from above


      if (special.indexOf(e.keyCode) !== -1 || // Ctrl+A
      e.keyCode === 65 && e.ctrlKey === true || // Ctrl+C
      e.keyCode === 67 && e.ctrlKey === true || // Ctrl+X
      e.keyCode === 88 && e.ctrlKey === true || // home, end, left, right
      e.keyCode >= 35 && e.keyCode <= 39) {
        return; // allow
      } // if alpha only: a-z/A-Z


      if (binding.modifiers.alpha && e.keyCode >= 65 && e.keyCode <= 90) {
        return; // allow
      } // if number only: number keys without shift OR numpad number keys


      if (binding.modifiers.number && (e.shiftKey && e.keyCode >= 48 && e.keyCode <= 57 || e.keyCode >= 96 && e.keyCode <= 105)) {
        // on autorise que 2 chiffres apres la virgule et un seul signe négatif au début
        var regex = /^-?\d+\.?\d{0,1}$/;

        if (e.target.value && e.target.value !== '-' && !regex.test(e.target.value)) {
          e.preventDefault(); // prevent
        } // --


        return; // allow
      } // otherwise stop the keystroke


      e.preventDefault(); // prevent
    }); // end addEventListener
  } // end bind

}); // end directive

Vue.directive('test', {
  bind: function bind(el, binding) {// el.class = binding.arg
  }
});