import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.trim";
import { login as _login, passwordReminder as _passwordReminder, passwordReset as _passwordReset, getUserInfo as _getUserInfo } from '@/api/user';
import { getCompanyIdsLinkedToUser } from '@/api/user_has_company';
import { getToken, setToken, removeToken } from '@/helpers/auth';
import { hasFullAccessToCompany } from '@/helpers/utils';
import { resetRouter } from '@/router';
var state = {
  token: getToken(),
  user: ''
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USER: function SET_USER(state, user) {
    state.user = user;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var email = userInfo.email,
        password = userInfo.password;
    return new Promise(function (resolve, reject) {
      _login({
        email: email.trim(),
        password: password
      }).then(function (response) {
        commit('SET_TOKEN', response.token);
        setToken(response.token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user password reminder
  passwordReminder: function passwordReminder(_ref2, userInfo) {
    var commit = _ref2.commit;
    var email = userInfo.email;
    return new Promise(function (resolve, reject) {
      _passwordReminder({
        email: email.trim()
      }).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user password reset
  passwordReset: function passwordReset(_ref3, userInfo) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      _passwordReset(userInfo).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref4) {
    var commit = _ref4.commit,
        state = _ref4.state;
    return new Promise(function (resolve, reject) {
      commit('SET_TOKEN', '');
      commit('SET_USER', '');
      removeToken();
      resetRouter();
      resolve();
    });
  },
  // get user info
  getUserInfo: function getUserInfo(_ref5) {
    var commit = _ref5.commit,
        state = _ref5.state;
    return new Promise(function (resolve, reject) {
      _getUserInfo(state.token).then(function (response) {
        var user = response[0];

        if (user.id > 0) {
          // commit('SET_USER', user)
          return resolve(user);
        }

        return reject(new Error('Verification failed, please Login again.'));
      });
    }).then(function (user) {
      return new Promise(function (resolve, reject) {
        // if (user.key_role === 'superadmin') {
        if (hasFullAccessToCompany(user.key_role)) {
          commit('SET_USER', user);
          return resolve(user);
        }

        getCompanyIdsLinkedToUser().then(function (response) {
          user.companies = response.map(function (o) {
            return o.comp_company_id;
          });
          commit('SET_USER', user);
          return resolve(user);
        });
      });
    }).catch(function (error) {
      return error;
    });
  },
  // remove token
  resetToken: function resetToken(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_USER', '');
      removeToken();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};