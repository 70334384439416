import "core-js/modules/es.object.to-string";
var routes = [{
  path: '/user-dashboard',
  name: 'UserDashboard',
  order: 10,
  meta: {
    title: 'Tableau de bord',
    icon: 'dashboard'
  },
  redirect: {
    name: 'Dashboard'
  },
  component: function component() {
    return import('@/layout');
  },
  children: [{
    path: '/dashboard',
    name: 'Dashboard',
    component: function component() {
      return import('@/views/app/dashboard/DashboardModule');
    },
    meta: {
      title: 'Tableau de bord',
      activeMenu: '/dashboard',
      breadcrumb: false
    }
  }]
}];
export default routes;