import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import * as apiNotification from '@/api/notification';
import moment from 'moment';
var state = {
  unreadCounter: 0,
  readCounter: 0,
  notifications: []
};
var mutations = {
  SET_UNREADCOUNTER: function SET_UNREADCOUNTER(state, unreadCounter) {
    state.unreadCounter = unreadCounter;
  },
  SET_READCOUNTER: function SET_READCOUNTER(state, readCounter) {
    state.readCounter = readCounter;
  },
  SET_NOTIFICATIONS: function SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  }
};
var actions = {
  read: function read(_ref, idUser) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      apiNotification.getAllByUserId(idUser).then(function (results) {
        if (JSON.stringify(results) !== JSON.stringify(state.notifications)) {
          commit('SET_NOTIFICATIONS', results);
          commit('SET_READCOUNTER', results.filter(function (x) {
            return x.date_markasread !== null;
          }).length);
          commit('SET_UNREADCOUNTER', results.filter(function (x) {
            return x.date_markasread === null;
          }).length);
        }

        resolve(results);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  markasread: function markasread(_ref2, notification) {
    var dispatch = _ref2.dispatch;
    return new Promise(function (resolve, reject) {
      var data = {
        date_markasread: moment().format('YYYY-MM-DD HH:mm:ss')
      }; // mark as read

      apiNotification.update(notification.id, data).then(function (result) {
        if (result.affectedRows > 0) {
          dispatch('read', notification.id_user);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  markallasread: function markallasread(_ref3, idUser) {
    var dispatch = _ref3.dispatch;
    return new Promise(function (resolve, reject) {
      var data = {
        id_user: idUser
      }; // mark all user notifications as read

      apiNotification.markAllAsRead(data).then(function (result) {
        if (result.affectedRows > 0) {
          dispatch('read', idUser);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  markasnotified: function markasnotified(commit, notification) {
    return new Promise(function (resolve, reject) {
      var data = {
        date_notification: moment().format('YYYY-MM-DD HH:mm:ss')
      }; // mark as notified

      apiNotification.update(notification.id, data).then(function () {
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  markasdeleted: function markasdeleted(commit, notification) {
    return new Promise(function (resolve, reject) {
      var selection = notification.map(function (u) {
        return u.id;
      }).join(','); // mark as deleted

      apiNotification.markAsDeleted(selection).then(function () {
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};