import request from '@/helpers/request';
export function getAllByCaseId(caseId) {
  return request({
    url: '/crm-case-progress/case/' + caseId,
    method: 'get'
  });
}
export function getById(id) {
  return request({
    url: '/crm-cases/' + id,
    method: 'get'
  });
}
/*
export function update (id, data) {
  return request({
    url: '/crm-case-progress/' + id,
    method: 'put',
    data
  })
}

export function insert (data) {
  return request({
    url: '/crm-case-progress',
    method: 'post',
    data
  })
}
*/