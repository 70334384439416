import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.includes";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "/var/www/lacroix_test/extranet/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { Routes } from '@/router';
import * as apiPermission from '@/api/permission';

function hasPermission(route) {
  // console.log(route.name, state.acl)
  if (route.name && state.acl) {
    return state.acl.includes(route.name);
  } else {
    return false;
  }
}

export function filterProtectedRoutes(routes) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (hasPermission(tmp)) {
      if (tmp.children) {
        tmp.children = filterProtectedRoutes(tmp.children);
      }

      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  // addRoutes: [],
  permission: [],
  rules: [],
  acl: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    // state.addRoutes = routes
    state.routes = routes;
  },
  SET_PERMISSION: function SET_PERMISSION(state, permission) {
    state.permission = permission;
    state.acl = state.permission.acl;
    state.rules = state.permission.rules;
  },
  SET_ACL: function SET_ACL(state, acl) {
    state.acl = acl;
    state.permission.acl = acl;
  },
  SET_RULES: function SET_RULES(state, rules) {
    state.rules = rules;
    state.permission.rules = rules;
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, user) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      var accessedRoutes = filterProtectedRoutes(Routes);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  },
  getPermission: function getPermission(_ref2, user) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      apiPermission.getByRole(user.key_role).then(function (response) {
        var permissions = response[0];
        commit('SET_PERMISSION', permissions);
        commit('SET_ACL', permissions.acl);
        commit('SET_RULES', permissions.rules);
        resolve(permissions);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};