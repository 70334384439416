import "core-js/modules/es.object.to-string";
var routes = [{
  path: '/admin/permission',
  name: 'AdminPermission',
  order: 20,
  meta: {
    title: 'Gestion des permissions',
    icon: 'permission'
  },
  redirect: {
    name: 'PermissionList'
  },
  component: function component() {
    return import('@/layout/empty');
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/admin/permission/PermissionList');
    },
    name: 'PermissionList',
    meta: {
      title: 'Permissions',
      activeMenu: '/admin/permission'
    }
  }, {
    path: 'new',
    hidden: true,
    component: function component() {
      return import('@/views/admin/permission/PermissionNew');
    },
    name: 'PermissionNew',
    meta: {
      title: 'Ajouter une permission',
      activeMenu: '/admin/permission'
    }
  }, {
    path: 'edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return import('@/views/admin/permission/PermissionEdit');
    },
    name: 'PermissionEdit',
    meta: {
      title: 'Modifier une permission',
      activeMenu: '/admin/permission'
    }
  }]
}];
export default routes;