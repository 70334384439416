import request from '@/helpers/request';
export function getAll(payload) {
  var filters = payload.filters;
  return request({
    url: '/crm-porte',
    method: 'post',
    data: {
      filters: filters
    },
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}
export function getAllMarque(payload) {
  var filters = payload.filters;
  return request({
    url: '/crm-porte/marque',
    method: 'post',
    data: {
      filters: filters
    },
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}
export function getAllType(payload) {
  var filters = payload.filters;
  return request({
    url: '/crm-porte/type',
    method: 'post',
    data: {
      filters: filters
    },
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}
export function getAllLieu(payload) {
  var filters = payload.filters;
  return request({
    url: '/crm-porte/lieu',
    method: 'post',
    data: {
      filters: filters
    },
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}
export function getAllByCompanyId(companyId) {
  return request({
    url: '/crm-porte/company/' + companyId,
    method: 'get',
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}
export function getById(id) {
  return request({
    url: '/crm-porte/' + id,
    method: 'get'
  });
}