import request from '@/helpers/request';
export function login(data) {
  return request({
    url: '/auth',
    method: 'post',
    data: data
  });
}
export function passwordReminder(data) {
  return request({
    url: "/user/send-password-reminder-mail/".concat(data.email),
    method: 'get'
  });
}
export function passwordReset(data) {
  console.log(data);
  return request({
    url: '/user/reset-password',
    method: 'put',
    data: data
  });
}
export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  });
}
export function getUserInfo(token) {
  return request({
    url: '/user/me',
    method: 'get',
    params: {
      token: token
    }
  });
}
export function getByEmail(email) {
  return request({
    url: '/user/email/' + email,
    method: 'get'
  });
}
export function getById(id) {
  return request({
    url: '/user/' + id,
    method: 'get'
  });
}
export function getAllBySocieteId(id) {
  return request({
    url: '/user/societe/' + id,
    method: 'get'
  });
}
export function update(id, data) {
  return request({
    url: '/user/' + id,
    method: 'put',
    data: data
  });
}
export function insert(data) {
  return request({
    url: '/user',
    method: 'post',
    data: data
  });
}
export function deleteById(id) {
  return request({
    url: '/user/' + id,
    method: 'delete'
  });
}
export function getAll() {
  return request({
    url: '/user',
    method: 'get'
  });
}
export function getAllActif() {
  return request({
    url: '/user/actif',
    method: 'get'
  });
}
export function bulkDelete(ids) {
  return request({
    url: '/user/' + ids,
    method: 'delete'
  });
}