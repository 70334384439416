import "core-js/modules/es.object.to-string";
var routes = [{
  path: '/admin/sys-sqllog',
  name: 'AdminSysSqllog',
  order: 110,
  meta: {
    title: 'Logs Sql',
    icon: 'file'
  },
  redirect: '/admin/sys_sqllog',
  component: function component() {
    return import('@/layout/empty');
  },
  children: [{
    path: '',
    // path: '/admin/sys_sqllog',
    component: function component() {
      return import('@/views/admin/sys_sqllog/SysSqlLogList');
    },
    name: 'SysSqlLogList',
    meta: {
      title: 'Logs SQL',
      activeMenu: '/admin/sys-sqllog'
    }
  }]
}];
export default routes;