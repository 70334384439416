import "core-js/modules/es.object.to-string";
var routes = [{
  path: '/crm-channel',
  name: 'CrmChannel',
  order: 20,
  meta: {
    title: 'Sage CRM : Channel',
    icon: 'crm-channel'
  },
  redirect: {
    name: 'CrmChannelModule'
  },
  component: function component() {
    return import('@/layout/empty');
  },
  children: [{
    path: '/crm/crm-channel',
    component: function component() {
      return import('@/views/crm/crm_channel/CrmChannelModule');
    },
    name: 'CrmChannelModule',
    meta: {
      title: 'CRM Channels',
      activeMenu: '/crm/crm-channel'
    }
  }]
}];
export default routes;