import request from '@/helpers/request';
export function getAllByInterventionId(companyId) {
  return request({
    url: '/crm-opportunity-progress/opportunity/' + companyId,
    method: 'get'
  });
}
export function getById(id) {
  return request({
    url: '/crm-opportunity-progress/' + id,
    method: 'get'
  });
}