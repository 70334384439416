import "core-js/modules/es.object.to-string";
var routes = [{
  path: '/admin/user',
  name: 'AdminUser',
  order: 40,
  meta: {
    title: 'Gestion des utilisateurs',
    icon: 'users'
  },
  redirect: {
    name: 'UserList'
  },
  component: function component() {
    return import('@/layout/empty');
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/admin/user/UserList');
    },
    name: 'UserList',
    meta: {
      title: 'Utilisateurs',
      activeMenu: '/admin/user'
    }
  }, {
    path: 'new',
    hidden: true,
    component: function component() {
      return import('@/views/admin/user/UserNew');
    },
    name: 'UserNew',
    meta: {
      title: 'Ajouter un utilisateur',
      activeMenu: '/admin/user'
    }
  }, {
    path: 'edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return import('@/views/admin/user/UserEdit');
    },
    name: 'UserEdit',
    meta: {
      title: 'Modifier un utilisateur',
      activeMenu: '/admin/user'
    }
  }, {
    path: 'manage-link/:id(\\d+)',
    hidden: true,
    component: function component() {
      return import('@/views/admin/user/UserCrmLinkManager');
    },
    name: 'UserCrmLinkManager',
    meta: {
      title: 'Lier un utilisateur à la CRM',
      activeMenu: '/admin/user'
    }
  }]
}];
export default routes;