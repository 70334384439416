import request from '@/helpers/request';
export function getAll() {
  return request({
    url: '/crm-cases',
    method: 'get',
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}
export function getAllByCompanyId(companyId) {
  return request({
    url: '/crm-cases/company/' + companyId,
    method: 'get',
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}
export function getAllByPorteId(porteId) {
  return request({
    url: '/crm-cases/porte/' + porteId,
    method: 'get'
  });
}
export function getById(id) {
  return request({
    url: '/crm-cases/' + id,
    method: 'get'
  });
}
export function update(id, data) {
  return request({
    url: '/crm-cases/' + id,
    method: 'put',
    data: data
  });
}
export function insert(data) {
  return request({
    url: '/crm-cases',
    method: 'post',
    data: data,
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
    maxBodyLength: 6 * 1000 * 1000 // 6Mb

  });
}