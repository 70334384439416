import "core-js/modules/es.object.to-string";
var routes = [{
  path: '/userprofil',
  name: 'UserProfil',
  hidden: true,
  redirect: {
    name: 'Profil'
  },
  meta: {
    title: 'Informations personnelles'
  },
  component: function component() {
    return import('@/layout');
  },
  children: [{
    path: '/profil',
    name: 'Profil',
    component: function component() {
      return import('@/views/profil');
    },
    meta: {
      title: 'Profil utilisateur',
      activeMenu: '/profil',
      breadcrumb: false
    }
  }]
}];
export default routes;