import "core-js/modules/es.object.to-string";
var routes = [{
  path: '/admin/societe',
  name: 'AdminSociete',
  order: 30,
  meta: {
    title: 'Gestion des sociétés',
    icon: 'societe'
  },
  redirect: {
    name: 'SocieteList'
  },
  component: function component() {
    return import('@/layout/empty');
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/admin/societe/SocieteList');
    },
    name: 'SocieteList',
    meta: {
      title: 'Sociétés',
      activeMenu: '/admin/societe'
    }
  }, {
    path: 'new',
    hidden: true,
    component: function component() {
      return import('@/views/admin/societe/SocieteNew');
    },
    name: 'SocieteNew',
    meta: {
      title: 'Ajouter une société',
      activeMenu: '/admin/societe'
    }
  }, {
    path: 'edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return import('@/views/admin/societe/SocieteEdit');
    },
    name: 'SocieteEdit',
    meta: {
      title: 'Modifier une société',
      activeMenu: '/admin/societe'
    }
  }]
}];
export default routes;