import "core-js/modules/es.array.filter";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import Vue from 'vue';
import * as utils from '@/helpers/utils';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');
Vue.filter('toCurrency', function (value) {
  if (value === '' || typeof value === 'undefined') return;
  if (value === 0) value = Math.abs(value); // pour éviter d'afficher le signe - quand value = 0

  var formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});
Vue.filter('toCurrencyWithZero', function (value) {
  if (typeof value === 'undefined') return;
  if (value === 0) value = Math.abs(value); // pour éviter d'afficher le signe - quand value = 0

  var formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});
Vue.filter('toPercentage', function (value) {
  if (!isNaN(value)) {
    return Number(value * 100).toFixed(2) + ' %';
  }
});
Vue.filter('toPercentageFormat', function (value) {
  if (!isNaN(value)) {
    return Number(value) + ' %';
  }
});
Vue.filter('toDate', function (value) {
  if (value && typeof value !== 'undefined') {
    return moment(String(value)).format('DD/MM/YYYY');
  }
});
Vue.filter('toDateTime', function (value) {
  return utils.toDateTime(value);
});
Vue.filter('toShortDate', function (value) {
  if (value && typeof value !== 'undefined') {
    return moment(String(value)).format('DD/MM/YY');
  }
});
Vue.filter('toDuration', function (value) {
  return utils.toDuration(value);
});
/************************************
 *  Formattage de durée spécifiquement pour le temps de suivi d'intervention
 ************************************
 *
 */

Vue.filter('toSuiviDuration', function (value) {
  return utils.toSuiviDuration(value);
});
/*
Vue.filter('toInterventionSuiviDuration', function (row) {
  return utils.toInterventionSuiviDuration(row)
})
*/

Vue.filter('formatSiret', function (value) {
  return utils.formatSiret(value);
});
Vue.filter('formatAddress', function (row) {
  return utils.formatAddress(row);
}); // Le filtre utilise la fonction getLabelContactType déjà implémentée dans utils

Vue.filter('getLabelContactType', function (value) {
  return utils.getLabelContactType(value);
});
Vue.filter('ln2br', function (value) {
  return utils.ln2br(value);
});