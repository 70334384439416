import "core-js/modules/es.object.to-string";
var routes = [{
  path: '/admin/parametre',
  name: 'AdminParametre',
  order: 10,
  meta: {
    title: 'Gestion des Paramètres',
    icon: 'parametre'
  },
  redirect: {
    name: 'ParametreList'
  },
  component: function component() {
    return import('@/layout/empty');
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/admin/parametre/ParametreList');
    },
    name: 'ParametreList',
    meta: {
      title: 'Paramètres',
      activeMenu: '/admin/parametre'
    }
  }, {
    path: 'new',
    hidden: true,
    component: function component() {
      return import('@/views/admin/parametre/ParametreNew');
    },
    name: 'ParametreNew',
    meta: {
      title: 'Ajouter un paramètre',
      activeMenu: '/admin/parametre'
    }
  }, {
    path: 'edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return import('@/views/admin/parametre/ParametreEdit');
    },
    name: 'ParametreEdit',
    meta: {
      title: 'Modifier un paramètre',
      activeMenu: '/admin/parametre'
    }
  }]
}];
export default routes;