import "core-js/modules/es.object.freeze";
export var USER = Object.freeze({
  PROFIL: {
    SUPER_ADMIN: 'superadmin',
    ADMINISTRATEUR: 'administrateur',
    ADMINISTRATEUR_AGENCE: 'admin_agence',
    DIRECTION: 'direction',
    ADMINISTRATIF: 'admin',
    TECHNIQUE: 'tech'
  }
});
export var DRAPEAUX_PRAXEDO = Object.freeze({
  0: {
    value: '0',
    label: 'Dépannage',
    cls: 'etech-flag-0'
  },
  1: {
    value: '1',
    label: 'Option Astreinte',
    cls: 'etech-flag-1'
  },
  2: {
    value: '2',
    label: 'Intervention à faire à 2 techniciens',
    cls: 'etech-flag-2'
  },
  3: {
    value: '3',
    label: 'Terme échu',
    cls: 'etech-flag-3'
  },
  4: {
    value: '4',
    label: 'Contrat Personnalisé',
    cls: 'etech-flag-4'
  },
  5: {
    value: '5',
    label: 'Contrat Tous Dépannages',
    cls: 'etech-flag-5'
  },
  6: {
    value: '6',
    label: 'Contrat Tous Risques',
    cls: 'etech-flag-6'
  },
  7: {
    value: '7',
    label: 'Partenaire',
    cls: 'etech-flag-7'
  },
  8: {
    value: '8',
    label: 'A encaisser',
    cls: 'etech-flag-8'
  },
  9: {
    value: '9',
    label: 'Travaux Selon Devis',
    cls: 'etech-flag-9'
  },
  10: {
    value: '10',
    label: 'Prendre RDV',
    cls: 'etech-flag-10'
  },
  11: {
    value: '11',
    label: 'Contrat 2 visites',
    cls: 'etech-flag-11'
  },
  12: {
    value: '12',
    label: 'Impayés',
    cls: 'etech-flag-12'
  },
  13: {
    value: '13',
    label: 'Attestation TVA à remplir',
    cls: 'etech-flag-13'
  },
  14: {
    value: '14',
    label: 'Matériel au bureau',
    cls: 'etech-flag-14'
  },
  15: {
    value: '15',
    label: 'Matériel chez le fournisseur',
    cls: 'etech-flag-15'
  }
});
export var CONTACT = Object.freeze({
  TYPE: {
    COMMERCIAL: {
      value: 'COMMERCIAL',
      label: 'Commercial'
    },
    MAINTENANCE: {
      value: 'MAINTENANCE',
      label: 'Maintenance'
    }
  }
});
export var TICKET = Object.freeze({
  TYPE_DEMANDE: {
    INTERVENTION: {
      label: 'Demande d\'intervention',
      values: {
        DEPANNAGE: {
          value: 'DEPANNAGE',
          label: 'Dépannage'
        },
        MAINTENANCE: {
          value: 'MAINTENANCE',
          label: 'Maintenance'
        }
      }
    },
    INFORMATION: {
      label: 'Demande d\'information',
      values: {
        FACTURE: {
          value: 'FACTURE',
          label: 'Facture'
        },
        DEVIS: {
          value: 'DEVIS',
          label: 'Devis'
        },
        TECHNIQUE: {
          value: 'TECHNIQUE',
          label: 'Technique'
        },
        AUTRE: {
          value: 'AUTRE',
          label: 'Autre, précisez :'
        }
      }
    }
  }
});
export var LIBRARY = Object.freeze({
  /* ---------------------------------------
   *  Taille limite d'upload en Mo
   * ---------------------------------------
   *  A faire correspondre avec la propriété `MAX_UPLOAD_SIZE`
   *  de la constantes `LIBRARY` côté API
   *  (cf. [API]/src/helpers/constantes.js
   *
   */
  // TODO : Prévoir un appel API au démarrage de l'extranet pour récupérer ce paramètre
  MAX_UPLOAD_SIZE: 1024 * 1000 * 3,
  // 3Mo

  /* ---------------------------------------
   *  Types de fichiers autorisés à uploader
   * ---------------------------------------
   *  A faire correspondre avec la propriété `ALLOWED_TYPE`
   *  de la constantes `LIBRARY` côté API
   *  (cf. [API]/src/helpers/constantes.js
   *
   */
  // ALLOWED_TYPE: '*'
  ALLOWED_TYPE: ['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/jpg', 'image/png']
});