import "core-js/modules/es.object.to-string";
var routes = [{
  path: '/admin/contact',
  name: 'AdminContact',
  order: 55,
  meta: {
    title: 'Gestion des Contacts',
    icon: 'contact'
  },
  redirect: {
    name: 'ContactList'
  },
  component: function component() {
    return import('@/layout/empty');
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/admin/contact/ContactList');
    },
    name: 'ContactList',
    meta: {
      title: 'Contacts',
      activeMenu: '/admin/contact'
    }
  }, {
    path: 'new',
    hidden: true,
    component: function component() {
      return import('@/views/admin/contact/ContactNew');
    },
    name: 'ContactNew',
    meta: {
      title: 'Ajouter un contact',
      activeMenu: '/admin/contact'
    }
  }, {
    path: 'edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return import('@/views/admin/contact/ContactEdit');
    },
    name: 'ContactEdit',
    meta: {
      title: 'Modifier un contact',
      activeMenu: '/admin/contact'
    }
  }]
}];
export default routes;