import "core-js/modules/es.object.to-string";
var routes = [{
  path: '/admin/agence',
  name: 'AdminAgence',
  order: 50,
  meta: {
    title: 'Gestion des agences',
    icon: 'agence'
  },
  redirect: {
    name: 'AgenceList'
  },
  component: function component() {
    return import('@/layout/empty');
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/admin/agence/AgenceList');
    },
    name: 'AgenceList',
    meta: {
      title: 'Agences',
      activeMenu: '/admin/agence'
    }
  }, {
    path: 'new',
    hidden: true,
    component: function component() {
      return import('@/views/admin/agence/AgenceNew');
    },
    name: 'AgenceNew',
    meta: {
      title: 'Ajouter une agence',
      activeMenu: '/admin/agence'
    }
  }, {
    path: 'edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return import('@/views/admin/agence/AgenceEdit');
    },
    name: 'AgenceEdit',
    meta: {
      title: 'Modifier une agence',
      activeMenu: '/admin/agence'
    }
  }]
}];
export default routes;