import "core-js/modules/es.object.to-string";
var routes = [{
  path: '/crm-company',
  name: 'CrmCompany',
  order: 10,
  meta: {
    title: 'Sage CRM : Company',
    icon: 'crm-company'
  },
  redirect: {
    name: 'CrmCompanyModule'
  },
  component: function component() {
    return import('@/layout/empty');
  },
  children: [{
    path: '/crm/crm-company',
    component: function component() {
      return import('@/views/crm/crm_company/CrmCompanyModule');
    },
    name: 'CrmCompanyModule',
    meta: {
      title: 'CRM Companies',
      activeMenu: '/crm/crm-company'
    }
  }]
}];
export default routes;