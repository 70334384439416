import "core-js/modules/es.object.to-string";
var routes = [{
  path: '/user-notification',
  name: 'UserNotification',
  order: 40,
  meta: {
    title: 'Mes Notifications',
    icon: 'bell'
  },
  redirect: {
    name: 'Notification'
  },
  component: function component() {
    return import('@/layout');
  },
  children: [{
    path: '/notification',
    name: 'Notification',
    component: function component() {
      return import('@/views/notification/NotificationList');
    },
    meta: {
      title: 'Notifications',
      activeMenu: '/notification',
      breadcrumb: false
    }
  }]
}];
export default routes;