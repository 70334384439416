//
//
//
//
//
//
//
//
//
export default {
  name: 'App',
  data: function data() {
    return {
      scrolled: false,
      scrollY: 0
    };
  },
  methods: {
    handleScroll: function handleScroll() {
      this.scrolled = window.scrollY > 0;
      this.scrollY = window.scrollY; // console.log('scrolling...' + window.scrollY)
    }
  },
  created: function created() {
    window.addEventListener('scroll', this.handleScroll); // console.log('scroll event listener added')
  },
  destroyed: function destroyed() {
    window.removeEventListener('scroll', this.handleScroll); // console.log('scroll event listener destroyed')
  },
  computed: {
    handleScrollTopBtnVisibility: function handleScrollTopBtnVisibility() {
      // console.log((this.scrollY > 200 ? 'show' : 'hide') + ' back-to-top button')
      return this.scrollY > 200;
    }
  }
};