import "core-js/modules/es.object.to-string";
var defaultActiveMenu = '/dashboard';
var routes = [{
  path: '/document',
  name: 'Document',
  order: 24,
  meta: {
    title: 'Gestion des documents',
    icon: 'site'
  },
  redirect: {
    name: 'DocumentModule'
  },
  hidden: true,
  component: function component() {
    return import('@/layout');
  },
  children: [// DOCUMENTS
  // ----------------
  // Liste des documents
  {
    path: 'list',
    hidden: true,
    component: function component() {
      return import('@/views/app/document/DocumentModule');
    },
    name: 'DocumentModule',
    meta: {
      title: 'Liste des documents',
      activeMenu: defaultActiveMenu
    }
  }, // Fiche d'un Document (accès URL raccourcie)
  {
    path: ':documentId(\\d+)',
    hidden: true,
    component: function component() {
      return import('@/views/app/document/DocumentIndex');
    },
    name: 'DocumentIndex',
    redirect: {
      name: 'DocumentView'
    },
    meta: {
      title: 'Fiche d\'un document',
      activeMenu: defaultActiveMenu
    },
    children: [// Contenu par défaut
    {
      path: '',
      component: function component() {
        return import('@/views/app/document/DocumentView');
      },
      name: 'DocumentView',
      meta: {
        title: 'Vue détaillée d\'un document',
        activeMenu: defaultActiveMenu
      }
    }]
  }]
}];
export default routes;