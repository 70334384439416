import "core-js/modules/es.object.to-string";
var defaultActiveMenu = '/dashboard';
var routes = [{
  path: '/ticket',
  name: 'Ticket',
  order: 25,
  meta: {
    title: 'Gestion des tickets',
    icon: 'site'
  },
  redirect: {
    name: 'TicketModule'
  },
  hidden: true,
  component: function component() {
    return import('@/layout');
  },
  children: [// TICKETS
  // ----------------
  // Liste des Tickets
  {
    path: 'list',
    hidden: true,
    component: function component() {
      return import('@/views/app/ticket/TicketModule');
    },
    name: 'TicketModule',
    meta: {
      title: 'Liste des Tickets',
      activeMenu: defaultActiveMenu
    }
  }, // Fiche d'un Ticket
  {
    path: ':ticketId(\\d+)',
    hidden: true,
    component: function component() {
      return import('@/views/app/ticket/TicketIndex');
    },
    name: 'TicketIndex',
    redirect: {
      name: 'TicketView'
    },
    meta: {
      title: 'Fiche d\'un ticket',
      activeMenu: defaultActiveMenu
    },
    children: [// Contenu par défaut
    {
      path: '',
      component: function component() {
        return import('@/views/app/ticket/TicketView');
      },
      name: 'TicketView',
      meta: {
        title: 'Vue détaillée d\'un ticket',
        activeMenu: defaultActiveMenu
      }
    }, // Liste des Historiques Tickets
    {
      path: 'historique',
      hidden: true,
      component: function component() {
        return import('@/views/app/ticket/TicketHistoriqueList');
      },
      name: 'TicketHistoriqueList',
      meta: {
        title: 'Historiques de Tickets',
        activeMenu: defaultActiveMenu
      }
    }]
  }, // Création d'un Ticket
  {
    path: 'new',
    hidden: true,
    component: function component() {
      return import('@/views/app/ticket/TicketNew');
    },
    name: 'TicketNew',
    meta: {
      title: 'Création d\'un ticket',
      activeMenu: defaultActiveMenu
    }
  }]
}];
export default routes;