import request from '@/helpers/request';
export function getAllByUserId(id) {
  return request({
    url: '/notification/user/' + id,
    method: 'get'
  });
}
export function markAllAsRead(data) {
  return request({
    url: '/notification/markallasread',
    method: 'put',
    data: data
  });
}
export function update(id, data) {
  return request({
    url: '/notification/' + id,
    method: 'put',
    data: data
  });
}
export function markAsDeleted(ids) {
  return request({
    url: '/notification/' + ids,
    method: 'delete'
  });
}
export function insert(data) {
  return request({
    url: '/notification',
    method: 'post',
    data: data
  });
}