import "core-js/modules/es.object.to-string";
var routes = [{
  path: '/admin/notification',
  name: 'AdminNotification',
  order: 100,
  meta: {
    title: 'Gestion des notifications',
    icon: 'bell'
  },
  redirect: {
    name: 'NotificationNew'
  },
  component: function component() {
    return import('@/layout/empty');
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/admin/notification/NotificationNew');
    },
    name: 'NotificationNew',
    meta: {
      title: 'Tester notification',
      activeMenu: '/admin/notification'
    }
  }]
}];
export default routes;