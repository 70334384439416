import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.match";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

function loadLocaleMessages() {
  var locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  var messages = {};
  locales.keys().forEach(function (key) {
    var matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      var locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'fr',
  messages: loadLocaleMessages(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  formatFallbackMessages: true,
  silentFallbackWarn: true
});