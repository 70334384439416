import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";

/* eslint-disable */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
export function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}
export function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }

  return Array.isArray(arg);
}
export function isStrongPassword(value) {
  /*
  ^	                The password string will start this way
  (?=.*[a-z])	      The string must contain at least 1 lowercase alphabetical character
  (?=.*[A-Z])	      The string must contain at least 1 uppercase alphabetical character
  (?=.*[0-9])	      The string must contain at least 1 numeric character
  (?=.[!@#\$%\^&])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
  (?=.{8,})	        The string must be eight characters or longer
  */
  // const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')
  var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'); // const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})')

  return strongRegex.test(value);
}
export var Email = function Email(rule, value, callback) {
  if (value === '') {
    callback(new Error('Adresse email obligatoire'));
  } else {
    if (!validEmail(value)) {
      callback(new Error('Adresses email non valide'));
    }

    callback();
  }
};
export var Password = function Password(rule, value, callback) {
  if (value === '') {
    callback(new Error('Mot de passe obligatoire'));
  } else {
    if (!isStrongPassword(value)) {
      callback(new Error('Le mot de passe ne respecte pas les règles de sécurité (8 caractères mini dont 1 minuscule, 1 majuscule, 1 chiffre)'));
    }

    callback();
  }
};
export var ConfirmPassword = function ConfirmPassword(pwd1, pwd2) {
  if (pwd2 === '') {
    return new Error('Mot de passe de confirmation obligatoire');
  } else {
    if (pwd1 !== pwd2) {
      return new Error('Mot de passe de confirmation non valide');
    }
  }
};