import Cookies from 'js-cookie';
import * as apiApp from '@/api/app';
var state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  activeMenu: '',
  breadcrumb: '',
  gitInfo: {
    api: {
      lastTagVersion: 'vx.x.x',
      lastCommitHash: 'xxxxxx'
    },
    app: {
      lastTagVersion: 'vx.x.x',
      lastCommitHash: 'xxxxxx'
    }
  }
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;

    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  ACTIVE_MENU: function ACTIVE_MENU(state, menu) {
    state.activeMenu = menu;
  },
  TAB_MENU: function TAB_MENU(state, text) {
    state.breadcrumb = text;
  },
  SET_GIT_INFO: function SET_GIT_INFO(state, info) {
    state.gitInfo = info;
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  activeMenu: function activeMenu(_ref5, menu) {
    var commit = _ref5.commit;
    commit('ACTIVE_MENU', menu);
  },
  tabmenu: function tabmenu(_ref6, text) {
    var commit = _ref6.commit;
    commit('TAB_MENU', text);
  },

  /* ---------------------------------------
   *  Informations sur la branche de dépôt actuelle (API et APP)
   * ---------------------------------------
   *  - récupération du dernier tag
   *  - récupération du hash du dernier commit
   */
  getGitInfo: function getGitInfo(_ref7) {
    var commit = _ref7.commit,
        state = _ref7.state,
        dispatch = _ref7.dispatch;
    return apiApp.getVersion().then(function (results) {
      commit('SET_GIT_INFO', results);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};