var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _c(
        "div",
        {
          directives: [
            {
              name: "scroll-to",
              rawName: "v-scroll-to",
              value: "#app",
              expression: "'#app'"
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.handleScrollTopBtnVisibility,
              expression: "handleScrollTopBtnVisibility"
            }
          ],
          staticClass: "el-backtop",
          staticStyle: { right: "30px", bottom: "30px" }
        },
        [_c("i", { staticClass: "el-icon-caret-top" })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }