import request from '@/helpers/request';
export function getAll() {
  return request({
    url: '/permission',
    method: 'get'
  });
}
export function getById(id) {
  return request({
    url: '/permission/' + id,
    method: 'get'
  });
}
export function update(id, data) {
  return request({
    url: '/permission/' + id,
    method: 'put',
    data: data
  });
}
export function insert(data) {
  return request({
    url: '/permission',
    method: 'post',
    data: data
  });
}
export function deleteById(id) {
  return request({
    url: '/permission/' + id,
    method: 'delete'
  });
}
export function bulkDelete(ids) {
  return request({
    url: '/permission/' + ids,
    method: 'delete'
  });
} // ---------------------------

export function getByRole(keyRole) {
  return request({
    url: '/permission/role/' + keyRole,
    method: 'get'
  });
}