import defaultSettings from '@/settings';
var siteTitle = defaultSettings.siteTitle,
    fixedHeader = defaultSettings.fixedHeader,
    sidebarLogo = defaultSettings.sidebarLogo;
var state = {
  sidebarLogo: sidebarLogo,
  siteTitle: siteTitle,
  fixedHeader: fixedHeader
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
        value = _ref.value;

    // if (state.hasOwnProperty(key)) {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};